import { createStore } from 'vuex'
import { getLanguage } from '@/language'
import VuexPersistence from 'vuex-persist'


const vuexLocal = new VuexPersistence({
	storage: window.localStorage
})


export default createStore({
	state() {
		return {
			lang: getLanguage(),
			orders: [],
			fapiaoAmount: '',
			invoiceType: [],
			kptqcode: '',
			loading: false,
			isSHDR: false,
			kpinform: {},
			receive: {},
			gettime: '',
			orderBillNos: [],
			orderList: [],
			costomerList: [],
			invoiceRequest: {},
			invoiceRequestId: '',
			invoiceRequestAmount: '',
			invoiceRequestDatetime: '',
			invoiceRequestInvoiceType: '',
			nextPage: '',
			kpcodeStatus: true,
			taxNoStatus: true,
			compAddressStatus: true,
			bankStatus: true,
			phoneStatus: true,
			BankAddressStatus: true,
			memoqyStatus: true,
			memogrStatus: true,
			mobileStatus: true,
			emailStatus: true,
			buyerNamegrStatus: true,
			buyerNameqyStatus: true,
			receiveStatus: true,
			sjhStatus: true,
			yxStatus: true,
			pdfhref: '',
			backAddress: '',
		}
	},
	mutations: {
		setLang(state, value) {
			state.lang = value
		},
		setOrders(state, value) {
			state.orders = value
		},
		setFapiaoAmount(state, value) {
			state.fapiaoAmount = value
		},
		setInvoiceType(state, value) {
			state.invoiceType = value
		},
		setKptqcode(state, value) {
			state.kptqcode = value
		},
		setLoading(state, value) {
			state.loading = value
		},
		setReceive(state, value) {
			state.receive = value
		},
		setKpinform(state, value) {
			state.kpinform = value
		},
		setIsSHDR(state, value) {
			state.isSHDR = value
		},
		setGettime(state, value) {
			state.gettime = value
		},
		setOrderBillNos(state, value) {
			state.orderBillNos = value
		},
		setOrderList(state, value) {
			state.orderList = value
		},
		setInvoiceRequestId(state, value) {
			state.invoiceRequestId = value
		},
		setInvoiceRequestDatetime(state, value) {
			state.invoiceRequestDatetime = value
		},
		setInvoiceRequestAmount(state, value) {
			state.invoiceRequestAmount = value
		},
		setInvoiceRequestInvoiceType(state, value) {
			state.invoiceRequestInvoiceType = value
		},
		setNextPage(state, value) {
			state.nextPage = value
		},
		setInvoiceRequest(state, value) {
			state.invoiceRequest = value
		},
		setKpcodeStatus(state, value) {
			state.kpcodeStatus = value
		},
		setTaxNoStatus(state, value) {
			state.taxNoStatus = value
		},
		setCompAddressStatus(state, value) {
			state.compAddressStatus = value
		},
		setBankStatus(state, value) {
			state.bankStatus = value
		},
		setPhoneStatus(state, value) {
			state.phoneStatus = value
		},
		setBankAddressStatus(state, value) {
			state.BankAddressStatus = value
		},
		setMemoqyStatus(state, value) {
			state.memoqyStatus = value
		},
		setMemogrStatus(state, value) {
			state.memogrStatus = value
		},
		setMobileStatus(state, value) {
			state.mobileStatus = value
		},
		setEmailStatus(state, value) {
			state.emailStatus = value
		},
		setBuyerNameqyStatus(state, value) {
			state.buyerNameqyStatus = value
		},
		setBuyerNamegrStatus(state, value) {
			state.buyerNamegrStatus = value
		},
		setReceiveStatus(state, value) {
			state.receiveStatus = value
		},
		setYxStatus(state, value) {
			state.yxStatus = value
		},
		setSjhStatus(state, value) {
			state.sjhStatus = value
		},
		setPdfhref(state, value) {
			state.pdfhref = value
		},
		setBackAddress(state, value) {
			state.backAddress = value
		},
		setCostomerList(state, value) {
			state.costomerList = value
		},
	},
	plugins: [vuexLocal.plugin]
})

import { createI18n } from 'vue-i18n';
import zh from './zh-cn'
import en from './en'

const messages = {
	'zh': zh,
	'en': en
}
export function getLanguage() {
	if (localStorage.getItem('lang') != null) {
		return localStorage.getItem('lang');
	}
	const lang = (navigator.language || navigator.browserLanguage).toLowerCase()
	if (lang.indexOf("en") != -1) {
		localStorage.setItem('lang', 'en')
		return 'en'
	} else if (lang.indexOf("zh") != -1) {
		localStorage.setItem('lang', 'zh')
		return 'zh'
	} else {
		localStorage.setItem('lang', 'zh')
		return 'zh'
	}
}

export function getMessage() {
	return messages
}

const i18n = createI18n({
	legacy:false,
	locale: getLanguage(),
	fallbackLocale: 'en',
	// global: true,
	globalInjection: true,
	messages: getMessage()
})
export default i18n

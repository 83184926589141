const EN = {
	ManPage: {
		Pagetitle: 'Shanghai Disney Resort',
		PageContent: 'Apply for E-Fapiao',
		Inputbar: 'Please input fapiao extraction code',
		Inputbari: 'Fapiao extraction code',
		Remarks: "Can't find fapiao extraction code? Please click here",
		Remarkfjy: "Can't find transaction ID? Please",
		Remarkfzd: "Can't find receipt number? Please",
		Remarkft: "Can't find fapiao extraction code? Please",
		Remarkaf: ' click here',
		Button: 'Search',
		poptitle: 'Fapiao Instructions & FAQ',
		popcontent1: 'In order to ensure the health and safety of each visitor, according to relevant laws and government regulations, every visitor (including children) to Shanghai Disneyland must register the visitor information before visiting (click to register: https://www.shanghaidisneyresort.com/reopen-digital-form ). The information collected is only used for epidemic prevention. For details, please check the park instructions and admission registration instructions.',
		popcontent2: "Before you enter the park, our cast members will measure your temperature and ask you to show your 'health information code'. Please bring your original ID card (for mainland tourists, please bring the People's Republic of China resident ID card; for tourists from Hong Kong and Macao, please bring the Mainland Travel Permit for Hong Kong and Macao Residents or the People's Republic of China Travel Permit; for tourists from Taiwan, please bring the Mainland Travel Permit for Taiwan Residents or the People's Republic of China Travel Permit; for tourists from other countries and regions, please bring a valid passport), Shanghai Disney Resort will collect the ID number on your ID card when you enter the park.",
		popcontent3: "Since May 11, most scenic spots, amusement projects, some entertainment performances, shops and restaurants in Shanghai Disneyland have resumed operation on the basis of controlling the passenger flow. In order to ensure a safe distance, the beloved Disney friends have met tourists in a new way. They will appear in the theme parks of Shanghai Disneyland. Welcome back to this magical place. However, in the early stage of the park's resumption of operation, the close interaction with Disney friends and close group photos are still temporarily cancelled.",
		errtitle: 'Failed',
		errcont: 'The invoicable amount of small invoice is zero, and cannot be invoiced',
		closeyes: 'Confirm',
		closecancel: 'Cancel',
		barerrormsg: 'Please enter the correct fapiao extraction code',
		location: 'Where to find Fapiao Extraction Code',
		// location: 'Fapiao extraction code location',
		jycode: 'Where to find receipt number',
		zdcode: 'Where to find receipt number',
		findjycode: 'Where to find code',
		findzdcode: 'Where to find code',
	},
	ErrMsg: {
		incorrect: 'The extraction code was entered incorrectly. Please enter the correct extraction code',
		available: 'The order was not found or issuing fapiao is not available at this time. Please try again later. If this error still occurs after 24 hours, please contact customer service at 400-180-0000',
		obtaine: 'Currently no orders have been obtained. Please try again later',
		latestxy: 'Please use the latest receipt with transaction ID ',
		latestxe: ' dated ',
		latestxs: ' to apply for fapiao',
		latestsy: 'Please use the latest receipt with receipt number ',
		latestse: ' dated ',
		latestss: ' to apply for fapiao',
		current: 'The current order cannot be invoiced. Please contact customer service center at 400-180-0000',
		cancell: 'The current order has been cancelled. If you have any questions, please contact customer service at 400-180-0000',
		amount: 'The amount for the current order is 0 and cannot be invoiced. If you have any questions, please contact customer service at 400-180-0000',
		paper: 'A paper fapiao has been manually issued for this order. Please wait for the fapiao to be mailed. If you have any questions, please contact customer service at 400-180-0000',
		check: 'An E-Fapiao has been manually issued for this order. Please check the email address or text message provided when invoicing to receive the electronic fapiao. If you have any questions, please consult customer service at 400-180-0000',
	},
	AppPage: {
		Fapiaoinformation: 'Fapiao information',
		FapiaoFAQ: 'Fapiao Instructions & FAQ',
		Fapiaotype: 'Fapiao type',
		Valueaddec: 'VAT Ordinary E-Fapiao',
		Valueaddbes: 'Fully Digitalized E-Fapiao（VAT Special Fapiao）',
		Valueaddbec: 'Fully Digitalized E-Fapiao （VAT Ordinary Fapiao）',
		Titletype: 'Title type',
		chosetype: 'Select invoicing type*',
		chosetypei: 'Invoicing type*',
		Institution: 'Company ',
		Personal: 'Personal & Governmental Institution',
		Personalx: 'Personal',
		Personaly: '& Governmental Institution',
		Fapiaotitle: 'Fapiao title',
		inputtitle: 'Please input fapiao title*',
		inputtitlei: 'Fapiao title*',
		SelectTitle: 'Select Title',
		TaxRegisNumber: 'Tax Registration Number',
		inputnumber: 'Please input tax registration number*',
		inputnumberi: 'Tax registration number*',
		inputnumberx: 'Please input tax registration number',
		Showmoreinfo: 'Show more info',
		Hidemoreinfo: 'Hide more info',
		CompanyAddress: 'Company Address',
		inputaddress: 'Please input company address',
		inputaddressi: 'Company address',
		Bankname: 'Bank name',
		inputbankname: 'Please input bank name',
		inputbanknamei: 'Bank name',
		Telephone: 'Telephone',
		inputtelephone: 'Please input telephone',
		inputtelephonei: 'Input telephone',
		Bankaccount: 'Bank account number',
		inputaccount: 'Please input bank account number',
		inputaccounti: 'Bank account number',
		Remarks: 'Remarks',
		inputRemarks: 'Please input remarks',
		inputRemarksi: 'Remarks',
		information: 'Contact information',
		chose: 'Please input at least one of two contact method',
		Mobile: 'Mobile number',
		phonenumber: 'Please input mobile phone number',
		phonenumberi: 'Mobile phone number',
		Email: 'Email address',
		emailaddress: 'Please input email address',
		emailaddressi: 'Email address',
		sendmethod: 'The invoice will be sent to',
		Fapiaoamount: 'Fapiao amount',
		Details: 'Check Details',
		Collapse: 'Collapse details',
		Ordernumber: 'Order number',
		Transtime: 'Transaction time ',
		Transamount: 'Transaction amount',
		Transdetails: 'Transaction details',
		Item: 'Item',
		Amount: 'Amount',
		Price: 'Price',
		Issuing: 'Issuing Fapiao',
		Payment: 'Payment Details',
		Submit: 'Submit',
		Link1: 'In order to issue and send E-Fapiao, we need you to fill in specific information. We will only use this information for the above purposes after we collect it. Please review our',
		Link2: ' "Privacy Policy" ',
		Link3: 'to learn more about our privacy practices. After you click the the checkbox, you will be deemed to agree to our collection and processing of your above information in accordance with the',
		Link4: '',
		yes: 'yes',
		no: 'no',
	},
	ErrMsgAppPage: {
		title: 'Please input fapiao title',
		cortitle: 'Please input the correct fapiao title',
		regist: 'Please input tax registration number',
		corregist: 'Please input the correct tax registration number',
		mobile: 'Please input the correct mobile number',
		email: 'Please input the correct email address',
		receive: 'Please fill in at least one information to receive fapiao',
		Unable: 'Unable to issue fapiao whose title is not Disney entities and whose title is agreed with the issue entity.',
		company: 'Please input the correct company address',
		bankdeposit: 'Please input the correct bank of deposit',
		phone: 'Please input the correct phone number',
		bankaccount: 'Please input the correct bank account',
		remark: 'Please input the correct remark',
	},
	RePage: {
		ConIfm: 'Confirm Information',
		Fapiaoamount: 'Fapiao amount',
		Fapiaotype: 'Fapiao type',
		Valueaddec: 'VAT Ordinary E-Fapiao',
		Valueaddbes: 'Fully Digitalized E-Fapiao（VAT Special Fapiao）',
		Valueaddbec: 'Fully Digitalized E-Fapiao （VAT Ordinary Fapiao）',
		Fapiaotitle: 'Fapiao title',
		TaxRegisNumber: 'Tax Registration Number',
		Mobile: 'Mobile number',
		Email: 'Email address',
		Company: 'Company address',
		Bankdeposit: 'Bank of deposit',
		Phone: 'Phone number',
		Bankaccount: 'Bank account',
		Confirm: 'Confirm',
		Return: 'Return',
		Nothing: 'Nothing',
	},
	SubmitPage: {
		EFapiaopro: 'E-Fapiao application processing',
		EFapiaowait: 'E-Fapiao will be issued in 24 hours and sent to you, please wait patiently.',
		Faamount: 'Fapiao amount',
		Fatype: 'Fapiao type',
		Apptime: 'Application time',
	},
	VeriPage: {
		ViewFapiao: 'View E-Fapiao',
		inputfirst: 'Please input your latest Fapiao contact information',
		inputfirstf: 'Please input your latest Fapiao',
		inputfirsts: 'contact information',
		Mobile: 'Mobile Number',
		inputmobile: 'Please input the last four digits of your mobile number',
		inputmobilei: 'The last four digits of your mobile number',
		Email: 'Email Address',
		inputemail: 'Please input email address',
		inputemaili: 'Email address',
		Check: 'Check',
	},
	ErrMsgVeriPage: {
		Mobile: 'Please input the last four digits of your mobile number',
		Email: 'Please input email address',
		MobileOrEmail: 'Please input mobile number/ email address to receive fapiao',
		Check: 'The information does not match. Please confirm whether the input content is correct or contact the customer service center at  400-180-0000',
	},
	IsSucPage: {
		Efsuc: 'E-fapiao issued successfully',
		hassent: 'E-fapiao has been sent to you by contact information.',
		amount: 'Fapiao amount',
		type: 'Fapiao type',
		time: 'Application time',
		View: 'View Fapiao',
		Viewf: 'View',
		Viewt: 'Fapiao',
		Send: 'Send to mobile number or email',
		AddCards: 'Add to WeChat/Alipay Cards & Offers',
		sendphone: 'Send to mobile',
		sendphonef: 'Send to',
		sendphones: 'mobile',
		sendemail: 'Send to email',
		sendemailf: 'Send to',
		sendemails: 'email',
		addcard: 'Add to Cards',
		addcardf: 'Add to',
		addcardt: 'Cards',
		problem: 'There is a problem with invoicing?',
		contact: ' Contact the customer service center',
		poptitleo: 'Send Fapiao to email',
		poptitlez: 'Send Fapiao to mobile',
		popcont: 'Remaining sending times：',
		closeyes: 'Send',
		closecancel: 'Cancels',
		inputphone: 'Please input mobile number',
		inputphonei: 'Mobile number',
		inputemail: 'Please input email address',
		inputemaili: 'Email address',
		errmsge: 'Please enter the last 4 digits of the mobile phone number reserved for invoice application',
		errmsgf: 'Please enter the email address reserved for invoice application',
		sending: 'Sending...',
		sendsucem: 'The invoice has been sent to your mailbox:',
		sendsucph: 'The invoice has been sent to your mobile:',
	},
	PopMsgSucPage: {
		Failed: 'Failed to add to WeChat/Alipay Cards & Offers',
		contact: 'Fapiao failed to load. Please contact the customer service center at 400-180-0000',
		successfully: 'Add to WeChat/Alipay Cards & Offers successfully',
	},
	IsFailPage: {
		failed: 'E-Fapiao failed to issue',
		contact: 'E-Fapiao failed to issue. Please contact guest service at 400-180-0000 to query reasons.',
		amount: 'Fapiao amount',
		type: 'Fapiao type',
		time: 'Application time',
	},
	ViewPage: {
		remarks: '*Remark: the presentation below is only for demo and does not represent the actual fapiao. Please refer to the document sent to your contact information for actual fapiao.',
		button: 'Download PDF',
	},
	PopMsgViewPage: {
		download: 'Failed to download. Please confirm whether your mobile phone has opened the file saving permission.',
		load: 'Fapiao failed to load. Please contact the customer service center at 400-180-0000.',
		Downloaded: 'Fapiao Downloaded',
		success: 'Success',
	},
	PopSendFp: {
		Fapiao: 'Send Fapiao',
		Mobile: 'Mobile number/Email Address',
		Remaining: 'Remaining number of times to send',
		Send: 'Send',
	},
	PopSendErr: {
		mobile: 'Please input the correct mobile number',
		email: 'Please input the correct email address',
		load: 'Fapiao failed to load. Please contact the customer service center at 400-180-0000',
		success: 'Sent successfully, please check the fapiao through the contact information you provided',
		time: 'Fapiao sending limit reached. Please contact customer service center at 400-180-0000.',
	},
	ErrScenario: {
		Error: 'System Error',
		Try: 'Please close this page and try again',
	},
	FapiaoFAQ: {
		institle: 'Fapiao Instructions',
		insconfir: '*If you need a Fapiao, please apply 30 minutes after the payment is successful. In order to obtain your Fapiao, please keep this cashier’s receipt in good condition. In the case of a return or exchange, the latest receipt should be used for Fapiao issuance.',
		insconsec: '*Amounts paid using physical or digital gift cards, coupons or other forms of discounts are not included in the Fapiao issuance amount.',
		insconthi: '*Only electronic Value Added Tax (VAT) Ordinary Fapiao can be issued for self-service application. If paper-formed VAT Ordinary Fapiao is needed, please consult the store/restaurant  you made purchase or visit a Guest Services* or call 400-180-0000 for assistance. If VAT Special Fapiao is needed, or you encounter electronic Fapiao issuance failure, or the purchase exceeds 24 months, please visit a Guest Services* or call 400-180-0000 for assistance.',
		insconfor: '*Guest Services locations: Main Entrance Guest Services, Turnstile Guest Services, and Disneytown Guest Services',
		FAQtitle: 'FAQ',
		qsfir: 'Question: Does the Electronic VAT (‘Value Added Tax’) Ordinary Fapiao (‘E-Fapiao’) have same legal effect with the paper-formed VAT Ordinary Fapiao?',
		asfir: 'Answer: The Electronic VAT (‘Value Added Tax’) Ordinary Fapiao has the same legal effect as the paper-formed VAT Ordinary Fapiao and can be used as legal document for financial accounting or reimbursement.',
		qssec: 'Question: Question: How to apply for the paper-formed VAT Fapiao?',
		assec: 'Answer: Please contact the customer service for paper-formed Fapiao application.',
		qsthi: 'Question: Can I request to issue paper-formed VAT Ordinary Fapiao or VAT Special Fapiao after I accidentally requested an Electronic VAT Ordinary Fapiao (‘E-Fapiao’)?',
		asthi: 'Answer: Please visit a Guest Services or call 400-180-0000 for re-issuing the paper-formed Fapiao. The original E-Fapiao will be cancelled by the system automatically and cannot be used in any condition. The Paper formed Fapiao will be issued based on the latest payment amount.',
		qsfor: 'Question: I accidentally input the incorrect Fapiao information that caused the system issued an incorrect Fapiao. How can I do?',
		asfor: 'Answer: Please visit a Guest Services or call 400-180-0000 for re-issuing the Fapiao.',
		asforfir: 'If the original Fapiao is an Electronic VAT ordinary Fapiao (‘E-Fapiao’), you will receive a Red Fapiao, the original E-Fapiao will be cancelled and cannot be used in any condition. After you received the Red Fapiao, you can request the E-Fapiao by yourself via the receipt. Or you can leave the correct Fapiao information to the customer service. The E-Fapiao will be issued and send to your contract information used for Fapiao application. Please note if any refunds/returns occurred during the Fapiao issuance, the Fapiao will be issued based on the latest payment amount.',
		asforsec: 'If the original Fapiao is a paper-formed VAT ordinary Fapiao, please return it to us for Fapiao cancellation. After the paper-formed Fapiao being cancelled, it cannot be used in any condition. If you still need the paper-formed VAT ordinary Fapiao, please leave the correct Fapiao information and delivery address to the customer service. We will post it to you once the Fapiao is issued. Please note if any refunds/returns occurred during the Fapiao issuance, the Fapiao will be issued based on the latest payment amount.',
		asforthi: 'If the original Fapiao is paper-formed VAT Special Fapiao, please return it to us for Fapiao cancellation. After the paper-formed Fapiao being cancelled, it cannot be used in any condition. If you still need the paper-formed VAT Special Fapiao, please leave the correct Fapiao information and delivery address to the customer service. We will post it to you once the Fapiao is issued. Please note: if the original Fapiao is verified by the buyer, we will seek your and the buyer‘s assistance on Red Fapiao issuance before issue a new Fapiao;  if any refunds/returns occurred during the Fapiao issuance, the Fapiao will be issued based on the latest payment amount.',
		qsfif: 'Question: Why the Fapiao amount is inconsistent with the order amount?',
		asfif: 'Answer: ',
		asfiffir: 'The Fapiao is only issued with the actual payment amount which excludes coupons, amounts discounted, promotions, gift cards, etc.',
		asfifsec: 'If you have requested for any refunds/returns, the amount of refunds/returns will be deducted from the Fapiao amount and the amount of newly purchases/exchanges will be added.',
		qssix: 'Question: I received one receipt after purchased some merchandise, and then received another refund receipt after I requested partial refund/return. How can I request the E-Fapiao?',
		assix: 'Answer: Please use the latest receipt for Fapiao requesting. If you use the first receipt for Fapiao requesting, the system will remind you the latest receipt’s number and the transaction time. Please find the latest receipt for Fapiao requesting.',
		qssev: 'Question: After the Electronic VAT Ordinary Fapiao (‘E-Fapiao’) has been issued for my order, I request for refund/return for my order. How do I deal with the issued Fapiao?',
		assev: 'Answer: If it is partial refund/return, the original E-Fapiao will be cancelled in full; and a new E-Fapiao will be issued automatically base on the remaining amount of the order ( if any ) and will be sent to your the email address or mobile number which you used for E-Fapiao application.',
		qseigh: 'Question: Why I didn’t receive the E-Fapiao after application?',
		aseigh: 'Answer: ',
		aseighfir: 'Re-scan/enter the application page, if the Fapiao status is ‘E-Fapiao issued successful’, you can extract the E-Fapiao via the H5 page, or you can click the button ‘send the E-Fapiao’.',
		aseighsec: 'Re-scan/enter the application page, if the Fapiao status is ‘E-Fapiao failed to issue’, please contact the guest service for help.',
		aseighthi: 'If you have inputted the incorrect contact information and cannot check the E-Fapiao via application page, please visit a Guest Services or call 400-180-0000 for contact information modification.',
		qsnine: 'Question: Where can I validate the E-Fapiao?',
		asnine: 'Answer: ',
		asninefir: 'Call the hotline of the National Tax Bureau 12366;',
		asninesec: 'Check the E-Fapiao on the website of ‘National Tax Bureau’s VAT Fapiao Validation Platform’ (',
		asninethi: 'https://inv-veri.chinatax.gov.cn',
		asninefor: ');',
		asninefif: 'Bring the E-Fapiao to the local Tax Bureau hall for validation.',
		qsten: 'Question: Why the Fapiao cannot be founded on the website of ‘National Tax Bureau’s VAT Fapiao Validation Platform’?',
		asten: 'Answer: There might be some delay for uploading the Fapiao issuance information to the Tax Bureau. It is suggested to check the Fapiao after 1-2 working days after the Fapiao issuance date.',
	},

}
export default EN

import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import { router } from './router.js';
import VueAxios from 'vue-axios'
import axios from './axios'
import i18n from './language/index.js';
import { Toast } from 'vant';

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueAxios,axios);
app.use(i18n);
app.use(Toast);
app.mount('#app');
